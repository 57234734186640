// src/GetCookie.ts
var GetCookie = (name) => {
  const cookie = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
  return cookie ? cookie.pop() ?? "" : "";
};

// src/ScreenBreakpoints.ts
var TABLET_BREAKPOINT = 768;
var DESKTOP_SMALL_BREAKPOINT = 1024;
var DESKTOP_BREAKPOINT = 1280;
var DESKTOP_LARGE_BREAKPOINT = 1366;
var ULTRAWIDE_BREAKPOINT = 1920;

// src/Sleep.ts
var Sleep = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time);
  });
};

// src/Slugify.ts
var Slugify = (value, options) => {
  const a3 = "\xE0\xE1\xE2\xE4\xE6\xE3\xE5\u0101\u0103\u0105\xE7\u0107\u010D\u0111\u010F\xE8\xE9\xEA\xEB\u0113\u0117\u0119\u011B\u011F\u01F5\u1E27\xEE\xEF\xED\u012B\u012F\xEC\u0142\u1E3F\xF1\u0144\u01F9\u0148\xF4\xF6\xF2\xF3\u0153\xF8\u014D\xF5\u0151\u1E55\u0155\u0159\xDF\u015B\u0161\u015F\u0219\u0165\u021B\xFB\xFC\xF9\xFA\u016B\u01D8\u016F\u0171\u0173\u1E83\u1E8D\xFF\xFD\u017E\u017A\u017C\xB7/_,:;";
  const b2 = "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a3.split("").join("|"), "g");
  value = value.toString().toLocaleLowerCase();
  if (!options?.keepSpaces)
    value = value.replace(/\s+/g, "-");
  if (!options?.keepSpecialCharacters)
    value = value.replace(p, (c2) => b2.charAt(a3.indexOf(c2)));
  if (!options?.keepAnd)
    value = value.replace(/&/g, "-and-");
  if (!options?.keepNonWordCharacters) {
    if (options?.keepSpaces)
      value = value.replace(/[^\w^\s]+/g, "");
    else
      value = value.replace(/[^\w-]+/g, "");
  }
  if (!options?.keepMultipleDashes)
    value = value.replace(/--+/g, "-");
  return value.replace(/^-+/, "").replace(/-+$/, "");
};

// src/UtilsElementPosition.ts
var UtilsElementPosition = (elementRef) => {
  let element = elementRef.current ?? elementRef;
  let x2 = 0, y3 = 0;
  while (element) {
    x2 += element.offsetLeft - element.scrollLeft + element.clientLeft;
    y3 += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent;
  }
  return { x: x2, y: y3 };
};

// src/UtilsMask.ts
var UtilsMask = (mask, value) => {
  if (!mask)
    return value;
  if (!value?.length)
    return "";
  let _value = value.replace(/[^a-zA-Z0-9]/gi, "").split("");
  let _mask = mask.split("");
  for (let i2 = 0; ; i2++) {
    if (i2 > mask.length)
      break;
    if (_mask[i2] !== "x") {
      continue;
    } else {
      _mask[i2] = _value[0];
      _value.splice(0, 1);
      if (_value.length === 0) {
        const increment = _mask[i2 + 1] !== "x" && value.length === i2 + 2 ? i2 + 2 : i2 + 1;
        _mask.splice(increment, _mask.length);
        break;
      }
    }
  }
  return _mask.join("");
};

// src/UtilsUuid.ts
import { v4 } from "uuid";
var UtilsUuid = v4;

// src/Validation.ts
var IsEmailValid = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

// src/UtilsMoneyFormatter.ts
var UtilsMoneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

// src/enum/PaymentType.ts
var PaymentType = /* @__PURE__ */ ((PaymentType2) => {
  PaymentType2["Default"] = "default";
  PaymentType2["Mastercard"] = "mastercard";
  PaymentType2["Visa"] = "visa";
  PaymentType2["Amex"] = "amex";
  PaymentType2["Discover"] = "discover";
  PaymentType2["PayAtRestaurant"] = "payatrestaurant";
  PaymentType2["AddACard"] = "addacard";
  PaymentType2["ApplePay"] = "apple-pay";
  PaymentType2["GooglePay"] = "google-pay";
  return PaymentType2;
})(PaymentType || {});

// src/enum/InputModes.ts
var InputModes = /* @__PURE__ */ ((InputModes2) => {
  InputModes2["Text"] = "text";
  InputModes2["Decimal"] = "decimal";
  InputModes2["Numeric"] = "numeric";
  InputModes2["Tel"] = "tel";
  InputModes2["Search"] = "search";
  InputModes2["Email"] = "email";
  InputModes2["Url"] = "url";
  InputModes2["None"] = "none";
  return InputModes2;
})(InputModes || {});

// src/UtilsGetDateDiff.ts
var UtilsGetDateDiffInDays = (firstDate, secondDate) => {
  const millisecondsPerDay = 1e3 * 60 * 60 * 24;
  const firstDateUtc = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
  const secondDateUtc = Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());
  return Math.floor((secondDateUtc - firstDateUtc) / millisecondsPerDay);
};

// src/Storage.ts
var Storage = {
  Local: {
    Set: (key, value) => {
      window.localStorage.setItem(key, JSON.stringify(value));
    },
    Get: (key) => {
      const item = window.localStorage.getItem(key);
      if (item) {
        try {
          return JSON.parse(item);
        } catch (err) {
          return void 0;
        }
      }
      return void 0;
    },
    Remove: (key) => {
      window.localStorage.removeItem(key);
    },
    Clear: () => {
      window.localStorage.clear();
    }
  },
  Session: {
    Set: (key, value) => {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    },
    Get: (key) => {
      const item = window.sessionStorage.getItem(key);
      if (item) {
        try {
          return JSON.parse(item);
        } catch (err) {
          return void 0;
        }
      }
      return void 0;
    },
    Remove: (key) => {
      window.sessionStorage.removeItem(key);
    },
    Clear: () => {
      window.sessionStorage.clear();
    }
  }
};

// src/UtilsGetProductTag.ts
var UtilsGetProductTag = (product) => {
  const tag = product?.metadata?.find((metadata) => metadata.key == "product-highlight-display")?.value;
  if (!tag)
    return null;
  return tag === "app-only" ? "app_only" : tag;
};

// src/GaEvent.ts
var GaEvent = class {
  constructor() {
    this.Global = new Global();
    this.getItemBrand = (brand) => {
      return brand === process.env.BRAND_CORPORATE ? "corporate" : "franchise";
    };
    this.formatStringToAlphanumeric = (string) => {
      const lowerCaseString = string.toLowerCase();
      const alphanumericString = lowerCaseString.replace(/[^a-z0-9]/gi, " ");
      return alphanumericString.trim();
    };
    this.getItemModifiers = (product) => {
      let variant = "";
      product.choices.forEach((choice, index) => {
        const delimiter = index === 0 ? "" : "~";
        const optionString = `${delimiter}${choice.name}|${choice.id}|${choice.cost}|${choice.quantity}`;
        variant += optionString;
      });
      return variant || null;
    };
    this.getItemVariablePrice = (product) => {
      const isPriceVariable = product.choices.some((choice) => {
        return choice.cost > 0;
      });
      return isPriceVariable ? "variable" : "fixed";
    };
  }
  push(obj) {
    window?.dataLayer?.push?.({ ...this.Global, ...obj });
  }
  getPageTitle() {
    if (typeof window !== "undefined") {
      return document.title;
    }
    return "";
  }
  CarouselClick(description) {
    this.push({
      event_label: description,
      event_action: `carousel|${window.location.pathname}`,
      event_category: "site promotion",
      event: "promo_click"
    });
  }
  CarouselView(pageTitle) {
    this.push({
      event_label: pageTitle,
      event_action: "carousel view",
      event_category: "carousel",
      event: "carousel_view"
    });
  }
  PageView(pageTitle, prevPageTitle, pageType, siteSection) {
    this.push({
      event: "page_view",
      event_label: pageTitle,
      event_action: window.location.href,
      event_category: "pages",
      previous_page_title: prevPageTitle ?? "entry_page",
      page_type: pageType,
      site_section: siteSection
    });
  }
  TopNavigation(label) {
    this.push({
      event: "top_navigation",
      event_label: label,
      event_action: "top nav",
      event_category: "navigation"
    });
  }
  FooterNavigation(label) {
    this.push({
      event: "footer_navigation",
      event_label: label,
      event_action: "footer nav",
      event_category: "navigation"
    });
  }
  DineRewardsSignUp() {
    this.push({
      event_category: "signup",
      event_label: "reward signup success",
      event_action: "reward signup success",
      event: "rewards_registration_success"
    });
  }
  InternalLinkClick(destinationUrl, label) {
    this.push({
      event_label: label ? label : window.location.href,
      event_action: destinationUrl,
      event_category: destinationUrl ? destinationUrl : "internal link click",
      event: "internal_link_click"
    });
  }
  OutboundLinkClick(destinationUrl) {
    this.push({
      event_label: window.location.href,
      event_action: destinationUrl,
      event_category: "outbound link click",
      event: "outbound_link_click"
    });
  }
  ProfileButtonsClick(buttonName) {
    this.push({
      event: "click",
      event_action: buttonName,
      event_category: "my_account_menu"
    });
  }
  SocialShareClicks(label) {
    this.push({
      event_label: label,
      event_action: "share",
      event_category: "social",
      event: "social_share"
    });
  }
  FormStart(formName, formLocation) {
    this.push({
      event_label: formName,
      event_action: "start",
      event_category: "form",
      event: "form_start",
      form_location: formLocation
    });
  }
  FormAttempt(formName, formLocation) {
    this.push({
      event_label: formName,
      event_action: "attempt",
      event_category: "form",
      event: "form_attempt",
      form_location: formLocation
    });
  }
  CheckoutSuccess(formName, formLocation) {
    this.push({
      event_label: formName,
      event_action: "success",
      event_category: "form",
      event: "form_success",
      form_location: formLocation
    });
  }
  FormSuccess(formName, formLocation, variant) {
    this.push({
      event_label: `dine_rewards_singup_form_` + formName,
      event_action: "success",
      event_category: "form",
      event: "form_success",
      form_location: formLocation,
      variant
    });
  }
  ErrorView(errorMessage, pageTitle) {
    this.push({
      event_label: Slugify(errorMessage, { keepSpaces: true }),
      event_action: pageTitle ?? "",
      event_category: "error message",
      event: "error_message"
    });
  }
  GetOnTheListStart(storeId) {
    this.push({
      event_label: storeId,
      event_action: "start",
      event_category: "waitlist",
      event: "waitlist_start"
    });
  }
  GetOnTheListComplete(storeId, guestsAndTime) {
    this.push({
      event_label: storeId,
      event_action: guestsAndTime,
      event_category: "waitlist",
      event: "waitlist_complete"
    });
  }
  GetOnTheListNextStepButton(storeId, guestsAndTime) {
    this.push({
      event_label: storeId,
      event_action: guestsAndTime,
      event_category: "waitlist",
      event: "waitlist_button_click"
    });
  }
  GetOnTheListSubmitButton(storeId, guestsAndTime) {
    this.push({
      event_label: storeId,
      event_action: guestsAndTime,
      event_category: "waitlist",
      event: "waitlist_button_click"
    });
  }
  SpecialReservationStart(pageTitle) {
    this.push({
      event: "special_reservation_start",
      event_name: "special_reservation_start",
      event_category: "special_reservation",
      event_action: "start",
      method: pageTitle
    });
  }
  SpecialReservationDetailsSubmitted(pageTitle, action, storeId) {
    this.push({
      event: "special_reservation_details_submitted",
      event_name: "special_reservation_details_submitted",
      event_category: "special_reservation",
      event_action: action,
      method: pageTitle,
      event_label: storeId
    });
  }
  SpecialReservationSignupSuccess(pageTitle, storeId) {
    this.push({
      event: "special_reservation_signup_success",
      event_name: "special_reservation_signup_success",
      event_category: "special_reservation",
      event_action: "signup_success",
      method: pageTitle,
      event_label: storeId
    });
  }
  ViewItemList(category, brand) {
    const items = category.products.map((p) => {
      return {
        item_list_id: category.id,
        item_name: this.formatStringToAlphanumeric(p.name),
        item_id: p.id,
        product_tag: UtilsGetProductTag(p),
        item_brand: this.getItemBrand(brand),
        item_variant: null,
        item_category: category.name
      };
    });
    this.push({
      event: "view_item_list",
      items,
      event_label: location.pathname,
      event_action: "product impression",
      event_category: "shopping"
    });
  }
  ViewItemListProductSuggestion(products) {
    const items = products.map((p) => {
      return {
        item_name: this.formatStringToAlphanumeric(p.product.name),
        item_id: p.product.id,
        product_tag: p.productTag,
        item_brand: p.brand ? this.getItemBrand(p.brand) : null,
        item_variant: null,
        item_category: p.category
      };
    });
    const item_list_id = products.map((p) => {
      return {
        item_id: p.product.id
      };
    });
    this.push({
      event: "view_item_list",
      item_list_id,
      items,
      item_list_name: "dollar count suggestion",
      event_label: "ordersummary",
      event_action: "product impressions",
      event_category: "shopping"
    });
  }
  SelectItem(product, storeId, brand, category, eventName) {
    this.push({
      event: eventName,
      event_label: product.name.toUpperCase(),
      event_action: "product click",
      event_category: "shopping",
      items: [
        {
          item_variant: null,
          item_list_name: "menu page",
          item_list_id: storeId,
          item_id: product.id,
          product_tag: UtilsGetProductTag(product),
          item_name: this.formatStringToAlphanumeric(product.name),
          item_brand: this.getItemBrand(brand),
          item_category: this.formatStringToAlphanumeric(category)
        }
      ]
    });
  }
  ViewItemListCMS(category, brand) {
    const items = category.products.map((p) => {
      return {
        item_list_id: category.id,
        item_name: this.formatStringToAlphanumeric(p.name),
        item_id: p.id,
        product_tag: UtilsGetProductTag(p),
        item_brand: this.getItemBrand(brand),
        item_variant: null,
        item_category: category.name,
        item_list_name: `menu_${this.formatStringToAlphanumeric(category.name)}`
      };
    });
    this.push({
      event: "view_item_list",
      items,
      event_label: location.pathname,
      event_action: "product impression",
      event_category: "shopping",
      item_list_name: `menu_${this.formatStringToAlphanumeric(category.name)}`
    });
  }
  SelectItemCMS(product, category, brand) {
    this.push({
      event: "select_item",
      event_category: "shopping",
      event_action: "product click",
      event_label: product.name.toUpperCase(),
      items: [
        {
          item_list_id: category.id,
          item_variant: null,
          item_id: product.id,
          product_tag: UtilsGetProductTag(product),
          item_name: this.formatStringToAlphanumeric(product.name),
          item_brand: this.getItemBrand(brand),
          item_category: this.formatStringToAlphanumeric(category.name)
        }
      ]
    });
  }
  MenuProductPageClick(buttonName) {
    this.push({
      event: "menu_product_page_click",
      event_category: "menu",
      event_action: "product_page_click",
      event_label: buttonName
    });
  }
  SearchStoreCms(storeCount, searchTerm) {
    const resultsCount = storeCount > 0 ? storeCount.toString() : "zero";
    this.push({
      event: "search",
      event_category: "store",
      event_attributes: "search",
      attributes: {
        resultsCount,
        term: searchTerm
      }
    });
  }
  ViewItem(product, quantity, storeId, brand, category, totalCost, isVariable) {
    this.push({
      event: "view_item",
      event_label: product.name.toUpperCase(),
      event_action: "product view",
      event_category: "shopping",
      items: [
        {
          item_variant: isVariable ? "variable" : "fixed",
          item_list_name: "menu page",
          item_list_id: storeId,
          item_id: product.id,
          product_tag: UtilsGetProductTag(product),
          item_name: this.formatStringToAlphanumeric(product.name),
          item_brand: this.getItemBrand(brand),
          item_category: this.formatStringToAlphanumeric(category),
          quantity,
          price: product.cost
        }
      ],
      value: totalCost
    });
  }
  AddToCart(product, quantity, storeId, brand, category, totalCost, origin, isVariable) {
    this.push({
      event: "add_to_cart",
      event_label: product.name.toUpperCase(),
      event_action: "add_to_cart_impression",
      event_category: "cart",
      currency: "USD",
      items: [
        {
          item_variant: isVariable ? "variable" : "fixed",
          item_list_name: origin,
          item_list_id: storeId,
          item_id: product.id,
          product_tag: UtilsGetProductTag(product),
          item_name: this.formatStringToAlphanumeric(product.name),
          item_brand: this.getItemBrand(brand),
          item_category: this.formatStringToAlphanumeric(category),
          quantity,
          price: product.cost
        }
      ],
      value: totalCost
    });
  }
  RemoveFromCart(product, productTag, quantity, storeId, brand, category) {
    this.push({
      value: product.totalcost,
      event: "remove_from_cart",
      event_label: product.name.toUpperCase(),
      event_action: "remove from cart",
      event_category: "shopping",
      currency: "USD",
      items: [
        {
          item_variant: this.getItemVariablePrice(product),
          item_list_name: "menu page",
          item_list_id: storeId,
          item_id: product.productId,
          product_tag: productTag,
          item_name: this.formatStringToAlphanumeric(product.name),
          item_brand: this.getItemBrand(brand),
          item_category: this.formatStringToAlphanumeric(category),
          quantity,
          price: product.basecost
        }
      ]
    });
  }
  ViewCart(basket, categories, brand) {
    const items = basket.products.map((product) => {
      const category = categories.find((c2) => c2.products.find((p) => p.id === product.productId));
      const prod = category?.products.find((p) => p.id === product.productId);
      return {
        item_variant: this.getItemVariablePrice(product),
        item_list_name: null,
        item_list_id: basket.vendorid.toString(),
        product_tag: UtilsGetProductTag(prod),
        item_name: this.formatStringToAlphanumeric(product.name),
        item_brand: this.getItemBrand(brand),
        item_category: category ? this.formatStringToAlphanumeric(category?.name) : "",
        price: product.totalcost,
        quantity: product.quantity
      };
    });
    this.push({
      value: basket.total,
      event: "view_cart",
      currency: "USD",
      items
    });
  }
  BeginCheckout(basket, categories) {
    const items = basket.products.map((product) => {
      const category = categories.find((c2) => c2.products.find((p) => p.id === product.productId));
      const prod = category?.products.find((p) => p.id === product.productId);
      return {
        item_id: product.productId,
        item_name: this.formatStringToAlphanumeric(product.name),
        item_category: category ? this.formatStringToAlphanumeric(category?.name) : "",
        product_tag: UtilsGetProductTag(prod),
        price: product.totalcost,
        quantity: product.quantity
      };
    });
    const utensils_values = [
      "include utensils",
      "include fork",
      "include napkin",
      "include knife",
      "include spoon",
      "include straw"
    ];
    const utensils = [];
    const product_sales = [];
    for (const item of items) {
      if (utensils_values.includes(item.item_name)) {
        utensils.push(item);
      } else {
        product_sales.push(item);
      }
    }
    const coupon = basket.discounts.find((d3) => d3.type === "Coupon")?.description;
    this.push({
      value: basket.total,
      event: "begin_checkout",
      currency: "USD",
      items: product_sales,
      include_utensils: utensils ?? null,
      checkout_step_name: "begin checkout",
      checkout_step_number: 1,
      coupon: coupon ?? null
    });
  }
  AddPaymentInfo(basket, productsTags, paymentType) {
    const items = basket.products.map((product, index) => {
      return {
        item_variant: this.getItemVariablePrice(product),
        item_list_name: null,
        item_list_id: basket.vendorid.toString(),
        product_tag: productsTags[index]
      };
    });
    const coupon = basket.discounts.find((d3) => d3.type === "Coupon")?.description;
    this.push({
      payment_type: paymentType,
      checkout_step_name: "payment",
      checkout_step_number: 2,
      event: "add_payment_info",
      currency: "USD",
      value: basket.total,
      items,
      coupon: coupon ?? null
    });
  }
  Purchase(handoffMethod, orderReceived, categories, products, paymentMethod, brand, paymentType, cardType) {
    const coupon = orderReceived.discounts.find((d3) => d3.type === "Coupon")?.description;
    const itemBrand = this.getItemBrand(brand);
    const items = products.map((product) => {
      const category = categories.find((c2) => c2.products.find((p) => p.id === product.productId));
      const prod = category?.products.find((p) => p.id === product.productId);
      return {
        item_list_name: "menu page",
        item_list_id: orderReceived.vendorid,
        item_id: product.productId,
        item_name: this.formatStringToAlphanumeric(product.name),
        item_brand: itemBrand,
        item_category: category?.name.toLowerCase(),
        product_tag: UtilsGetProductTag(prod),
        price: product.totalcost,
        quantity: product.quantity,
        coupon: null,
        discount: null,
        item_category5: this.getItemVariablePrice(product),
        item_variant: this.getItemModifiers(product)
      };
    });
    const utensils_values = [
      "include utensils",
      "include fork",
      "include napkin",
      "include knife",
      "include spoon",
      "include straw"
    ];
    const utensils = [];
    const product_sales = [];
    for (const item of items) {
      if (utensils_values.includes(item.item_name)) {
        utensils.push(item);
      } else {
        product_sales.push(item);
      }
    }
    this.push({
      shipping_tier: handoffMethod,
      event: "purchase",
      currency: "USD",
      value: orderReceived.total,
      items: product_sales,
      include_utensils: utensils ?? null,
      affiliation: paymentMethod,
      shipping: orderReceived.customerhandoffcharge,
      tax: orderReceived.salestax,
      transaction_id: orderReceived.id,
      coupon: coupon ?? null,
      tip_amount: orderReceived.tip,
      event_category: "shopping",
      event_action: "purchase",
      payment_type: paymentType,
      credit_card_type: cardType
    });
  }
  ErrorMessage(errorMessage, pageTitle) {
    this.push({
      event: "error_message",
      event_label: this.formatStringToAlphanumeric(errorMessage),
      event_action: pageTitle ?? this.getPageTitle(),
      event_category: "error message"
    });
  }
  AutoCompleteViewed() {
    this.push({
      event: "autocomplete_viewed",
      event_action: "view",
      event_category: "autocomplete"
    });
  }
  AutoCompleteClicked() {
    this.push({
      event: "autocomplete_clicked",
      event_action: "click",
      event_category: "autocomplete"
    });
  }
  SearchStore(addressSearched, storeCount, success, handoffMethod) {
    const resultCount = !success ? "zero" : storeCount > 0 ? storeCount.toString() : "zero";
    this.push({
      event: success ? "search_success" : "search_failure",
      event_label: addressSearched,
      event_category: "search:store",
      eventAttributes: {
        internal_search_destination: "search results page",
        search_result_count: resultCount,
        shipping_tier: handoffMethod
      }
    });
  }
  GeoLocationSuccess(storeId) {
    this.push({
      event_action: "geolocation allowed",
      event_category: "geolocation",
      event: "geo_location_success",
      event_label: storeId ?? "no store ID|store not selected"
    });
  }
  GeoLocationFailure(storeId) {
    this.push({
      event_label: storeId ?? "no store ID|store not selected",
      event_action: "geolocation denied",
      event_category: "geolocation",
      event: "geo_location_failure"
    });
  }
  GeoLocationDisplay(storeId) {
    this.push({
      event_label: storeId ?? "no store ID|store not selected",
      event_action: "geolocation view",
      event_category: "geolocation",
      event: "geo_location_display"
    });
  }
  DineRewardsLinkClick(origin) {
    this.push({
      event: "dine_rewards_link_click",
      event_label: "join dine rewards",
      event_action: origin,
      event_category: "internal link click"
    });
  }
  UseMyLocationClick() {
    this.push({
      event_label: "use my location",
      event_category: "internal link click",
      event: "use_my_location_click"
    });
  }
  MapUse() {
    this.push({
      event_label: "map use",
      event_category: "map interaction",
      event: "map_use"
    });
  }
  MapSelectRestaurant(selectedRestaurant) {
    this.push({
      event_label: "map select restaurant",
      event_category: "map interaction",
      event: "map_click",
      selected_restaurant: selectedRestaurant
    });
  }
  SearchResultsPageClick(handoffMethod) {
    this.push({
      event_label: "order now",
      event_action: "button click",
      event: "order_now_search_page_clicks",
      event_category: "order now search page clicks",
      eventAttributes: {
        shipping_tier: handoffMethod
      }
    });
  }
  UserHasLoggedIn() {
    this.push({
      event: "login",
      event_label: null,
      event_action: "login",
      event_category: "account",
      logged_in_status: "logged in"
    });
  }
  UserHasLoggedOut() {
    this.push({
      event: "logout",
      event_label: null,
      event_action: "logout",
      event_category: "account",
      logged_in_status: "logout out"
    });
  }
  UserExpiringRewards(userEmail, expiringFifteenDays, expiringThirtyDays) {
    this.push({
      event_action: "expiring rewards",
      event: "user_has_expiring_rewards",
      next_15_days: expiringFifteenDays,
      next_30_days: expiringThirtyDays,
      user_email: userEmail
    });
  }
  GeneratedPoints(generatedPoints) {
    this.push({
      event: "rewards_points_increase",
      event_label: generatedPoints + "",
      event_action: "points increase",
      event_category: "rewards updated"
    });
  }
  RedeemRewardClick(origin, rewardValue) {
    this.push({
      event: "redeem_rewards",
      event_category: origin,
      event_action: `reward redeemed ${rewardValue}`
    });
  }
  RedeemRewardAppClick(origin, redirectedUrl) {
    this.push({
      event: "rewards_redeem_app_click",
      event_label: origin,
      event_action: `redeem app click`,
      event_category: "internal link click",
      redirected_url: redirectedUrl
    });
  }
  RedeemRewardHighlightButtonClick(origin, redirectedUrl) {
    this.push({
      event: "feature_highlight",
      event_label: origin,
      event_action: `redeem app click`,
      event_category: "internal link click",
      redirected_url: redirectedUrl
    });
  }
  RedeemRedirectAppStore(origin, redirectedUrl) {
    this.push({
      event: "rewards_redeem_redirect_appstore",
      event_label: origin,
      event_action: `redeem redirect appStore`,
      event_category: "internal link click",
      redirected_url: redirectedUrl
    });
  }
  RecentAndFavesActive(userEmail) {
    this.push({
      event_action: "recent and faves active",
      event: "recent_and_faves_active",
      user_email: userEmail
    });
  }
  RecentAndFavesInactive(userEmail) {
    this.push({
      event_action: "recent and faves inactive",
      event: "recent_and_faves_inactive",
      user_email: userEmail
    });
  }
  FavoritesOrderAgainButtonClick() {
    this.push({
      event: "add_favorite",
      event_label: "/order/menu",
      event_action: "add|card click",
      event_category: "favorites"
    });
  }
  RecentOrdersOrderAgainButtonClick() {
    this.push({
      event: "add_recent_order",
      event_label: "/order/menu",
      event_action: "add|card click",
      event_category: "recent orders"
    });
  }
  OrderAgainHighlightButtonClick(category) {
    this.push({
      event: "feature_highlight",
      event_label: "/order/menu",
      event_action: "add|card click",
      event_category: category
    });
  }
  FavoritesShowDetailsButtonClick() {
    this.push({
      event: "view_favorite",
      event_label: "/order/menu",
      event_action: "view|card click",
      event_category: "favorites"
    });
  }
  RecentOrdersShowDetailsButtonClick() {
    this.push({
      event: "view_recent_order",
      event_label: "/order/menu",
      event_action: "view|card click",
      event_category: "recent orders"
    });
  }
  FavoritesButtonClick(favoriteCount, origin, isGuest) {
    this.push({
      event: "favorite_button_click",
      event_action: "button_click",
      event_category: "favorite",
      event_label: isGuest ? origin : `${origin}|${favoriteCount ? favoriteCount : 0}`
    });
  }
  RecentOrdersButtonClick(recentOrdersCount, origin, isGuest) {
    this.push({
      event_label: isGuest ? origin : `${origin}|${recentOrdersCount ? recentOrdersCount : 0}`,
      event_action: "button_click",
      event: "recent_orders_button_click",
      event_category: "recent_orders"
    });
  }
  RecentOrdersButtonImpression(recentOrdersCount) {
    this.push({
      event: "recent_orders_button_impression",
      event_category: "recent orders",
      event_label: `menu|${recentOrdersCount ? recentOrdersCount : 0}`,
      event_action: "button impression"
    });
  }
  FavoritesButtonImpression(favoriteCount) {
    this.push({
      event: "favorite_button_impression",
      event_category: "favorites",
      event_label: `menu|${favoriteCount ? favoriteCount : 0}`,
      event_action: "button impression"
    });
  }
  SearchButtonClick() {
    this.push({
      event: "search_click",
      event_label: " search button click",
      event_action: "search_button_click",
      event_category: "search:store"
    });
  }
  GetDirectionsClick(origin) {
    this.push({
      event: "get_direction_click",
      event_label: "get direction",
      event_action: origin,
      event_category: "get_direction"
    });
  }
  SearchAreaClick() {
    this.push({
      event: "search_area_click",
      event_label: "search this area",
      event_action: "search this area click",
      event_category: "search:store"
    });
  }
  PromotionalBannerClick(buttonName, bannerId, eventLabel) {
    this.push({
      event: "internal_banner_click",
      event_label: eventLabel,
      event_category: "promotional_banner",
      banner_id: bannerId,
      button_name: buttonName
    });
  }
  AppOnlyAddToCart(deepLink) {
    this.push({
      event_label: "app only",
      event_action: deepLink,
      event: "app_only_add_to_cart",
      event_category: "app only"
    });
  }
  SaveCustomizationClick(customizedCategory, item) {
    this.push({
      event: "save_customization_click",
      event_category: "customization",
      event_action: [customizedCategory, item]
    });
  }
  WaitlistCriteriaMet() {
    this.push({
      event: "waitlist_criteria_met",
      event_action: "criteria met",
      event_category: "get on the list"
    });
  }
  DineRewardsActivityTimelineFilter(optionSelected) {
    this.push({
      event: "apply_activity_filter",
      event_category: "activity filter",
      event_action: "apply filter",
      event_label: optionSelected
    });
  }
  DineRewardsSideDrawerNavigation(buttonName) {
    this.push({
      event: "menu_navigation",
      event_category: "navigation",
      event_action: "menu",
      event_label: buttonName
    });
  }
  SaveCustomizationSuccess(customizedCategory, item) {
    this.push({
      event: "save_customization_success",
      event_category: "customization",
      event_action: [customizedCategory, item]
    });
  }
  DineRewardsPointsTracker(currentPoints) {
    this.push({
      event: "points_tracker_view",
      event_category: "points tracker",
      event_action: "view_points",
      event_label: currentPoints
    });
  }
  DineRewardsDetailReward(rewardName, brand) {
    this.push({
      event: "view-rewards-details",
      event_category: "view-detail",
      event_action: brand,
      event_label: rewardName
    });
  }
  DineRewardsMissingPointsFormSubmit(brand, storeId, responseTitle) {
    this.push({
      event: "request_missing_points_success",
      event_category: "missing points",
      event_action: brand,
      event_label: storeId,
      response_title: responseTitle
    });
  }
  DineRewardsMissingPointsFormBegin() {
    this.push({
      event: "request_missing_points_start",
      event_category: "missing points"
    });
  }
  visitWebsite(brandName) {
    this.push({
      event: "visit_website",
      event_category: "visit website",
      event_action: brandName
    });
  }
  getOnTheWaitList(brandName) {
    this.push({
      event: "get_on_the_waitlist",
      event_category: "get on the list",
      event_action: brandName
    });
  }
  GlobalEvent() {
    this.push({
      event: "global"
    });
  }
  HomepageButtonClick(buttonName) {
    this.push({
      event: "homepage_button_click",
      event_label: buttonName,
      event_action: "homepage button click",
      event_category: "navigation"
    });
  }
  InternalLinkClickDr(destinationUrl, name) {
    this.push({
      event_label: destinationUrl,
      event_action: name,
      event_category: "internal link click",
      event: "internal_link_click"
    });
  }
  StartProfileEdit() {
    this.push({
      event: "profile_change_start",
      event_category: "profile change",
      event_action: "start",
      event_label: "/dashboard/member-profile"
    });
  }
  ProfileEditSuccess() {
    this.push({
      event: "profile_change_success",
      event_category: "profile change",
      event_action: "success",
      event_label: "/dashboard/member-profile"
    });
  }
  ProfileEditFailure() {
    this.push({
      event: "profile_change_failure",
      event_category: "profile change",
      event_action: "failure",
      event_label: "/dashboard/member-profile"
    });
  }
  MenuNavigation(buttonName) {
    this.push({
      event: "menu_navigation",
      event_category: "menu navigation",
      event_action: "Logged menu",
      event_label: buttonName
    });
  }
  HomePageScroll(sectionName) {
    this.push({
      event: "homepage_scroll",
      event_category: "homepage",
      event_action: "Scroll",
      event_label: sectionName
    });
  }
  RewardRegistrationSuccess() {
    this.push({
      event: "rewards_registration_success",
      event_category: "signup",
      event_action: "Reward signup success"
    });
  }
};
var Global = class {
  constructor() {
    this.store_id = "not selected";
    this.customer_id = null;
    this.logged_in_status = "not logged in";
    this.dine_rewards_user_id = null;
    this.ab_testing = null;
    this.dine_rewards_status = "unlinked";
    this.email_address_code = null;
    this.platform_name = "sitecore_maverick";
    this.dollar_rewards_available = null;
    this.event_category = null;
    this.event_label = null;
    this.event_action = null;
    this.page_type = null;
    this.previous_page_title = null;
    this.site_section = null;
    this.init();
  }
  init() {
    try {
      if ("localStorage" in window) {
        const stored = window.localStorage.getItem("ga-global");
        if (stored) {
          Object.assign(this, JSON.parse(stored));
        }
      }
    } catch {
    }
  }
  Set(key, value) {
    this[key] = value;
    this.persist();
  }
  persist() {
    if ("localStorage" in window) {
      window.localStorage.setItem("ga-global", JSON.stringify(this));
    }
  }
};
var instance = new GaEvent();

// src/UtilsDateWithoutUTCFormat.ts
var DateWithoutUTCFormat = (date) => {
  const today = new Date(date.getTime() - date.getTimezoneOffset() * 6e4);
  return today;
};

// src/UtilsPopUp.ts
var handleWithPopUp = (event, currentUrl, redirectUrl, openPopUpButton, width, height) => {
  event.preventDefault();
  window.open(redirectUrl, "WBI", `width=${width}, height=${height}, directories=no, location=no, menubar=no, resizable=no, scrollbars=1, status=no, toolbar=no`);
};

// ../../node_modules/@babel/runtime/helpers/esm/typeof.js
function _typeof(o) {
  "@babel/helpers - typeof";
  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(o2) {
    return typeof o2;
  } : function(o2) {
    return o2 && "function" == typeof Symbol && o2.constructor === Symbol && o2 !== Symbol.prototype ? "symbol" : typeof o2;
  }, _typeof(o);
}

// ../../node_modules/date-fns/esm/_lib/toInteger/index.js
function toInteger(dirtyNumber) {
  if (dirtyNumber === null || dirtyNumber === true || dirtyNumber === false) {
    return NaN;
  }
  var number = Number(dirtyNumber);
  if (isNaN(number)) {
    return number;
  }
  return number < 0 ? Math.ceil(number) : Math.floor(number);
}

// ../../node_modules/date-fns/esm/_lib/requiredArgs/index.js
function requiredArgs(required, args) {
  if (args.length < required) {
    throw new TypeError(required + " argument" + (required > 1 ? "s" : "") + " required, but only " + args.length + " present");
  }
}

// ../../node_modules/date-fns/esm/toDate/index.js
function toDate(argument) {
  requiredArgs(1, arguments);
  var argStr = Object.prototype.toString.call(argument);
  if (argument instanceof Date || _typeof(argument) === "object" && argStr === "[object Date]") {
    return new Date(argument.getTime());
  } else if (typeof argument === "number" || argStr === "[object Number]") {
    return new Date(argument);
  } else {
    if ((typeof argument === "string" || argStr === "[object String]") && typeof console !== "undefined") {
      console.warn("Starting with v2.0.0-beta.1 date-fns doesn't accept strings as date arguments. Please use `parseISO` to parse strings. See: https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#string-arguments");
      console.warn(new Error().stack);
    }
    return new Date(NaN);
  }
}

// ../../node_modules/date-fns/esm/addMilliseconds/index.js
function addMilliseconds(dirtyDate, dirtyAmount) {
  requiredArgs(2, arguments);
  var timestamp = toDate(dirtyDate).getTime();
  var amount = toInteger(dirtyAmount);
  return new Date(timestamp + amount);
}

// ../../node_modules/date-fns/esm/_lib/defaultOptions/index.js
var defaultOptions = {};
function getDefaultOptions() {
  return defaultOptions;
}

// ../../node_modules/date-fns/esm/_lib/getTimezoneOffsetInMilliseconds/index.js
function getTimezoneOffsetInMilliseconds(date) {
  var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
  utcDate.setUTCFullYear(date.getFullYear());
  return date.getTime() - utcDate.getTime();
}

// ../../node_modules/date-fns/esm/isDate/index.js
function isDate(value) {
  requiredArgs(1, arguments);
  return value instanceof Date || _typeof(value) === "object" && Object.prototype.toString.call(value) === "[object Date]";
}

// ../../node_modules/date-fns/esm/isValid/index.js
function isValid(dirtyDate) {
  requiredArgs(1, arguments);
  if (!isDate(dirtyDate) && typeof dirtyDate !== "number") {
    return false;
  }
  var date = toDate(dirtyDate);
  return !isNaN(Number(date));
}

// ../../node_modules/date-fns/esm/subMilliseconds/index.js
function subMilliseconds(dirtyDate, dirtyAmount) {
  requiredArgs(2, arguments);
  var amount = toInteger(dirtyAmount);
  return addMilliseconds(dirtyDate, -amount);
}

// ../../node_modules/date-fns/esm/_lib/getUTCDayOfYear/index.js
var MILLISECONDS_IN_DAY = 864e5;
function getUTCDayOfYear(dirtyDate) {
  requiredArgs(1, arguments);
  var date = toDate(dirtyDate);
  var timestamp = date.getTime();
  date.setUTCMonth(0, 1);
  date.setUTCHours(0, 0, 0, 0);
  var startOfYearTimestamp = date.getTime();
  var difference = timestamp - startOfYearTimestamp;
  return Math.floor(difference / MILLISECONDS_IN_DAY) + 1;
}

// ../../node_modules/date-fns/esm/_lib/startOfUTCISOWeek/index.js
function startOfUTCISOWeek(dirtyDate) {
  requiredArgs(1, arguments);
  var weekStartsOn = 1;
  var date = toDate(dirtyDate);
  var day = date.getUTCDay();
  var diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
  date.setUTCDate(date.getUTCDate() - diff);
  date.setUTCHours(0, 0, 0, 0);
  return date;
}

// ../../node_modules/date-fns/esm/_lib/getUTCISOWeekYear/index.js
function getUTCISOWeekYear(dirtyDate) {
  requiredArgs(1, arguments);
  var date = toDate(dirtyDate);
  var year = date.getUTCFullYear();
  var fourthOfJanuaryOfNextYear = new Date(0);
  fourthOfJanuaryOfNextYear.setUTCFullYear(year + 1, 0, 4);
  fourthOfJanuaryOfNextYear.setUTCHours(0, 0, 0, 0);
  var startOfNextYear = startOfUTCISOWeek(fourthOfJanuaryOfNextYear);
  var fourthOfJanuaryOfThisYear = new Date(0);
  fourthOfJanuaryOfThisYear.setUTCFullYear(year, 0, 4);
  fourthOfJanuaryOfThisYear.setUTCHours(0, 0, 0, 0);
  var startOfThisYear = startOfUTCISOWeek(fourthOfJanuaryOfThisYear);
  if (date.getTime() >= startOfNextYear.getTime()) {
    return year + 1;
  } else if (date.getTime() >= startOfThisYear.getTime()) {
    return year;
  } else {
    return year - 1;
  }
}

// ../../node_modules/date-fns/esm/_lib/startOfUTCISOWeekYear/index.js
function startOfUTCISOWeekYear(dirtyDate) {
  requiredArgs(1, arguments);
  var year = getUTCISOWeekYear(dirtyDate);
  var fourthOfJanuary = new Date(0);
  fourthOfJanuary.setUTCFullYear(year, 0, 4);
  fourthOfJanuary.setUTCHours(0, 0, 0, 0);
  var date = startOfUTCISOWeek(fourthOfJanuary);
  return date;
}

// ../../node_modules/date-fns/esm/_lib/getUTCISOWeek/index.js
var MILLISECONDS_IN_WEEK = 6048e5;
function getUTCISOWeek(dirtyDate) {
  requiredArgs(1, arguments);
  var date = toDate(dirtyDate);
  var diff = startOfUTCISOWeek(date).getTime() - startOfUTCISOWeekYear(date).getTime();
  return Math.round(diff / MILLISECONDS_IN_WEEK) + 1;
}

// ../../node_modules/date-fns/esm/_lib/startOfUTCWeek/index.js
function startOfUTCWeek(dirtyDate, options) {
  var _ref, _ref2, _ref3, _options$weekStartsOn, _options$locale, _options$locale$optio, _defaultOptions$local, _defaultOptions$local2;
  requiredArgs(1, arguments);
  var defaultOptions2 = getDefaultOptions();
  var weekStartsOn = toInteger((_ref = (_ref2 = (_ref3 = (_options$weekStartsOn = options === null || options === void 0 ? void 0 : options.weekStartsOn) !== null && _options$weekStartsOn !== void 0 ? _options$weekStartsOn : options === null || options === void 0 ? void 0 : (_options$locale = options.locale) === null || _options$locale === void 0 ? void 0 : (_options$locale$optio = _options$locale.options) === null || _options$locale$optio === void 0 ? void 0 : _options$locale$optio.weekStartsOn) !== null && _ref3 !== void 0 ? _ref3 : defaultOptions2.weekStartsOn) !== null && _ref2 !== void 0 ? _ref2 : (_defaultOptions$local = defaultOptions2.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.weekStartsOn) !== null && _ref !== void 0 ? _ref : 0);
  if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  }
  var date = toDate(dirtyDate);
  var day = date.getUTCDay();
  var diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
  date.setUTCDate(date.getUTCDate() - diff);
  date.setUTCHours(0, 0, 0, 0);
  return date;
}

// ../../node_modules/date-fns/esm/_lib/getUTCWeekYear/index.js
function getUTCWeekYear(dirtyDate, options) {
  var _ref, _ref2, _ref3, _options$firstWeekCon, _options$locale, _options$locale$optio, _defaultOptions$local, _defaultOptions$local2;
  requiredArgs(1, arguments);
  var date = toDate(dirtyDate);
  var year = date.getUTCFullYear();
  var defaultOptions2 = getDefaultOptions();
  var firstWeekContainsDate = toInteger((_ref = (_ref2 = (_ref3 = (_options$firstWeekCon = options === null || options === void 0 ? void 0 : options.firstWeekContainsDate) !== null && _options$firstWeekCon !== void 0 ? _options$firstWeekCon : options === null || options === void 0 ? void 0 : (_options$locale = options.locale) === null || _options$locale === void 0 ? void 0 : (_options$locale$optio = _options$locale.options) === null || _options$locale$optio === void 0 ? void 0 : _options$locale$optio.firstWeekContainsDate) !== null && _ref3 !== void 0 ? _ref3 : defaultOptions2.firstWeekContainsDate) !== null && _ref2 !== void 0 ? _ref2 : (_defaultOptions$local = defaultOptions2.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.firstWeekContainsDate) !== null && _ref !== void 0 ? _ref : 1);
  if (!(firstWeekContainsDate >= 1 && firstWeekContainsDate <= 7)) {
    throw new RangeError("firstWeekContainsDate must be between 1 and 7 inclusively");
  }
  var firstWeekOfNextYear = new Date(0);
  firstWeekOfNextYear.setUTCFullYear(year + 1, 0, firstWeekContainsDate);
  firstWeekOfNextYear.setUTCHours(0, 0, 0, 0);
  var startOfNextYear = startOfUTCWeek(firstWeekOfNextYear, options);
  var firstWeekOfThisYear = new Date(0);
  firstWeekOfThisYear.setUTCFullYear(year, 0, firstWeekContainsDate);
  firstWeekOfThisYear.setUTCHours(0, 0, 0, 0);
  var startOfThisYear = startOfUTCWeek(firstWeekOfThisYear, options);
  if (date.getTime() >= startOfNextYear.getTime()) {
    return year + 1;
  } else if (date.getTime() >= startOfThisYear.getTime()) {
    return year;
  } else {
    return year - 1;
  }
}

// ../../node_modules/date-fns/esm/_lib/startOfUTCWeekYear/index.js
function startOfUTCWeekYear(dirtyDate, options) {
  var _ref, _ref2, _ref3, _options$firstWeekCon, _options$locale, _options$locale$optio, _defaultOptions$local, _defaultOptions$local2;
  requiredArgs(1, arguments);
  var defaultOptions2 = getDefaultOptions();
  var firstWeekContainsDate = toInteger((_ref = (_ref2 = (_ref3 = (_options$firstWeekCon = options === null || options === void 0 ? void 0 : options.firstWeekContainsDate) !== null && _options$firstWeekCon !== void 0 ? _options$firstWeekCon : options === null || options === void 0 ? void 0 : (_options$locale = options.locale) === null || _options$locale === void 0 ? void 0 : (_options$locale$optio = _options$locale.options) === null || _options$locale$optio === void 0 ? void 0 : _options$locale$optio.firstWeekContainsDate) !== null && _ref3 !== void 0 ? _ref3 : defaultOptions2.firstWeekContainsDate) !== null && _ref2 !== void 0 ? _ref2 : (_defaultOptions$local = defaultOptions2.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.firstWeekContainsDate) !== null && _ref !== void 0 ? _ref : 1);
  var year = getUTCWeekYear(dirtyDate, options);
  var firstWeek = new Date(0);
  firstWeek.setUTCFullYear(year, 0, firstWeekContainsDate);
  firstWeek.setUTCHours(0, 0, 0, 0);
  var date = startOfUTCWeek(firstWeek, options);
  return date;
}

// ../../node_modules/date-fns/esm/_lib/getUTCWeek/index.js
var MILLISECONDS_IN_WEEK2 = 6048e5;
function getUTCWeek(dirtyDate, options) {
  requiredArgs(1, arguments);
  var date = toDate(dirtyDate);
  var diff = startOfUTCWeek(date, options).getTime() - startOfUTCWeekYear(date, options).getTime();
  return Math.round(diff / MILLISECONDS_IN_WEEK2) + 1;
}

// ../../node_modules/date-fns/esm/_lib/addLeadingZeros/index.js
function addLeadingZeros(number, targetLength) {
  var sign = number < 0 ? "-" : "";
  var output = Math.abs(number).toString();
  while (output.length < targetLength) {
    output = "0" + output;
  }
  return sign + output;
}

// ../../node_modules/date-fns/esm/_lib/format/lightFormatters/index.js
var formatters = {
  y: function y(date, token) {
    var signedYear = date.getUTCFullYear();
    var year = signedYear > 0 ? signedYear : 1 - signedYear;
    return addLeadingZeros(token === "yy" ? year % 100 : year, token.length);
  },
  M: function M(date, token) {
    var month = date.getUTCMonth();
    return token === "M" ? String(month + 1) : addLeadingZeros(month + 1, 2);
  },
  d: function d(date, token) {
    return addLeadingZeros(date.getUTCDate(), token.length);
  },
  a: function a(date, token) {
    var dayPeriodEnumValue = date.getUTCHours() / 12 >= 1 ? "pm" : "am";
    switch (token) {
      case "a":
      case "aa":
        return dayPeriodEnumValue.toUpperCase();
      case "aaa":
        return dayPeriodEnumValue;
      case "aaaaa":
        return dayPeriodEnumValue[0];
      case "aaaa":
      default:
        return dayPeriodEnumValue === "am" ? "a.m." : "p.m.";
    }
  },
  h: function h(date, token) {
    return addLeadingZeros(date.getUTCHours() % 12 || 12, token.length);
  },
  H: function H(date, token) {
    return addLeadingZeros(date.getUTCHours(), token.length);
  },
  m: function m(date, token) {
    return addLeadingZeros(date.getUTCMinutes(), token.length);
  },
  s: function s(date, token) {
    return addLeadingZeros(date.getUTCSeconds(), token.length);
  },
  S: function S(date, token) {
    var numberOfDigits = token.length;
    var milliseconds = date.getUTCMilliseconds();
    var fractionalSeconds = Math.floor(milliseconds * Math.pow(10, numberOfDigits - 3));
    return addLeadingZeros(fractionalSeconds, token.length);
  }
};
var lightFormatters_default = formatters;

// ../../node_modules/date-fns/esm/_lib/format/formatters/index.js
var dayPeriodEnum = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
};
var formatters2 = {
  G: function G(date, token, localize2) {
    var era = date.getUTCFullYear() > 0 ? 1 : 0;
    switch (token) {
      case "G":
      case "GG":
      case "GGG":
        return localize2.era(era, {
          width: "abbreviated"
        });
      case "GGGGG":
        return localize2.era(era, {
          width: "narrow"
        });
      case "GGGG":
      default:
        return localize2.era(era, {
          width: "wide"
        });
    }
  },
  y: function y2(date, token, localize2) {
    if (token === "yo") {
      var signedYear = date.getUTCFullYear();
      var year = signedYear > 0 ? signedYear : 1 - signedYear;
      return localize2.ordinalNumber(year, {
        unit: "year"
      });
    }
    return lightFormatters_default.y(date, token);
  },
  Y: function Y(date, token, localize2, options) {
    var signedWeekYear = getUTCWeekYear(date, options);
    var weekYear = signedWeekYear > 0 ? signedWeekYear : 1 - signedWeekYear;
    if (token === "YY") {
      var twoDigitYear = weekYear % 100;
      return addLeadingZeros(twoDigitYear, 2);
    }
    if (token === "Yo") {
      return localize2.ordinalNumber(weekYear, {
        unit: "year"
      });
    }
    return addLeadingZeros(weekYear, token.length);
  },
  R: function R(date, token) {
    var isoWeekYear = getUTCISOWeekYear(date);
    return addLeadingZeros(isoWeekYear, token.length);
  },
  u: function u(date, token) {
    var year = date.getUTCFullYear();
    return addLeadingZeros(year, token.length);
  },
  Q: function Q(date, token, localize2) {
    var quarter = Math.ceil((date.getUTCMonth() + 1) / 3);
    switch (token) {
      case "Q":
        return String(quarter);
      case "QQ":
        return addLeadingZeros(quarter, 2);
      case "Qo":
        return localize2.ordinalNumber(quarter, {
          unit: "quarter"
        });
      case "QQQ":
        return localize2.quarter(quarter, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return localize2.quarter(quarter, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return localize2.quarter(quarter, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  q: function q(date, token, localize2) {
    var quarter = Math.ceil((date.getUTCMonth() + 1) / 3);
    switch (token) {
      case "q":
        return String(quarter);
      case "qq":
        return addLeadingZeros(quarter, 2);
      case "qo":
        return localize2.ordinalNumber(quarter, {
          unit: "quarter"
        });
      case "qqq":
        return localize2.quarter(quarter, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return localize2.quarter(quarter, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return localize2.quarter(quarter, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  M: function M2(date, token, localize2) {
    var month = date.getUTCMonth();
    switch (token) {
      case "M":
      case "MM":
        return lightFormatters_default.M(date, token);
      case "Mo":
        return localize2.ordinalNumber(month + 1, {
          unit: "month"
        });
      case "MMM":
        return localize2.month(month, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return localize2.month(month, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return localize2.month(month, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  L: function L(date, token, localize2) {
    var month = date.getUTCMonth();
    switch (token) {
      case "L":
        return String(month + 1);
      case "LL":
        return addLeadingZeros(month + 1, 2);
      case "Lo":
        return localize2.ordinalNumber(month + 1, {
          unit: "month"
        });
      case "LLL":
        return localize2.month(month, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return localize2.month(month, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return localize2.month(month, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  w: function w(date, token, localize2, options) {
    var week = getUTCWeek(date, options);
    if (token === "wo") {
      return localize2.ordinalNumber(week, {
        unit: "week"
      });
    }
    return addLeadingZeros(week, token.length);
  },
  I: function I(date, token, localize2) {
    var isoWeek = getUTCISOWeek(date);
    if (token === "Io") {
      return localize2.ordinalNumber(isoWeek, {
        unit: "week"
      });
    }
    return addLeadingZeros(isoWeek, token.length);
  },
  d: function d2(date, token, localize2) {
    if (token === "do") {
      return localize2.ordinalNumber(date.getUTCDate(), {
        unit: "date"
      });
    }
    return lightFormatters_default.d(date, token);
  },
  D: function D(date, token, localize2) {
    var dayOfYear = getUTCDayOfYear(date);
    if (token === "Do") {
      return localize2.ordinalNumber(dayOfYear, {
        unit: "dayOfYear"
      });
    }
    return addLeadingZeros(dayOfYear, token.length);
  },
  E: function E(date, token, localize2) {
    var dayOfWeek = date.getUTCDay();
    switch (token) {
      case "E":
      case "EE":
      case "EEE":
        return localize2.day(dayOfWeek, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return localize2.day(dayOfWeek, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return localize2.day(dayOfWeek, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return localize2.day(dayOfWeek, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  e: function e(date, token, localize2, options) {
    var dayOfWeek = date.getUTCDay();
    var localDayOfWeek = (dayOfWeek - options.weekStartsOn + 8) % 7 || 7;
    switch (token) {
      case "e":
        return String(localDayOfWeek);
      case "ee":
        return addLeadingZeros(localDayOfWeek, 2);
      case "eo":
        return localize2.ordinalNumber(localDayOfWeek, {
          unit: "day"
        });
      case "eee":
        return localize2.day(dayOfWeek, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return localize2.day(dayOfWeek, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return localize2.day(dayOfWeek, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return localize2.day(dayOfWeek, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  c: function c(date, token, localize2, options) {
    var dayOfWeek = date.getUTCDay();
    var localDayOfWeek = (dayOfWeek - options.weekStartsOn + 8) % 7 || 7;
    switch (token) {
      case "c":
        return String(localDayOfWeek);
      case "cc":
        return addLeadingZeros(localDayOfWeek, token.length);
      case "co":
        return localize2.ordinalNumber(localDayOfWeek, {
          unit: "day"
        });
      case "ccc":
        return localize2.day(dayOfWeek, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return localize2.day(dayOfWeek, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return localize2.day(dayOfWeek, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return localize2.day(dayOfWeek, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  i: function i(date, token, localize2) {
    var dayOfWeek = date.getUTCDay();
    var isoDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
    switch (token) {
      case "i":
        return String(isoDayOfWeek);
      case "ii":
        return addLeadingZeros(isoDayOfWeek, token.length);
      case "io":
        return localize2.ordinalNumber(isoDayOfWeek, {
          unit: "day"
        });
      case "iii":
        return localize2.day(dayOfWeek, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return localize2.day(dayOfWeek, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return localize2.day(dayOfWeek, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return localize2.day(dayOfWeek, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  a: function a2(date, token, localize2) {
    var hours = date.getUTCHours();
    var dayPeriodEnumValue = hours / 12 >= 1 ? "pm" : "am";
    switch (token) {
      case "a":
      case "aa":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  b: function b(date, token, localize2) {
    var hours = date.getUTCHours();
    var dayPeriodEnumValue;
    if (hours === 12) {
      dayPeriodEnumValue = dayPeriodEnum.noon;
    } else if (hours === 0) {
      dayPeriodEnumValue = dayPeriodEnum.midnight;
    } else {
      dayPeriodEnumValue = hours / 12 >= 1 ? "pm" : "am";
    }
    switch (token) {
      case "b":
      case "bb":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  B: function B(date, token, localize2) {
    var hours = date.getUTCHours();
    var dayPeriodEnumValue;
    if (hours >= 17) {
      dayPeriodEnumValue = dayPeriodEnum.evening;
    } else if (hours >= 12) {
      dayPeriodEnumValue = dayPeriodEnum.afternoon;
    } else if (hours >= 4) {
      dayPeriodEnumValue = dayPeriodEnum.morning;
    } else {
      dayPeriodEnumValue = dayPeriodEnum.night;
    }
    switch (token) {
      case "B":
      case "BB":
      case "BBB":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return localize2.dayPeriod(dayPeriodEnumValue, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  h: function h2(date, token, localize2) {
    if (token === "ho") {
      var hours = date.getUTCHours() % 12;
      if (hours === 0)
        hours = 12;
      return localize2.ordinalNumber(hours, {
        unit: "hour"
      });
    }
    return lightFormatters_default.h(date, token);
  },
  H: function H2(date, token, localize2) {
    if (token === "Ho") {
      return localize2.ordinalNumber(date.getUTCHours(), {
        unit: "hour"
      });
    }
    return lightFormatters_default.H(date, token);
  },
  K: function K(date, token, localize2) {
    var hours = date.getUTCHours() % 12;
    if (token === "Ko") {
      return localize2.ordinalNumber(hours, {
        unit: "hour"
      });
    }
    return addLeadingZeros(hours, token.length);
  },
  k: function k(date, token, localize2) {
    var hours = date.getUTCHours();
    if (hours === 0)
      hours = 24;
    if (token === "ko") {
      return localize2.ordinalNumber(hours, {
        unit: "hour"
      });
    }
    return addLeadingZeros(hours, token.length);
  },
  m: function m2(date, token, localize2) {
    if (token === "mo") {
      return localize2.ordinalNumber(date.getUTCMinutes(), {
        unit: "minute"
      });
    }
    return lightFormatters_default.m(date, token);
  },
  s: function s2(date, token, localize2) {
    if (token === "so") {
      return localize2.ordinalNumber(date.getUTCSeconds(), {
        unit: "second"
      });
    }
    return lightFormatters_default.s(date, token);
  },
  S: function S2(date, token) {
    return lightFormatters_default.S(date, token);
  },
  X: function X(date, token, _localize, options) {
    var originalDate = options._originalDate || date;
    var timezoneOffset = originalDate.getTimezoneOffset();
    if (timezoneOffset === 0) {
      return "Z";
    }
    switch (token) {
      case "X":
        return formatTimezoneWithOptionalMinutes(timezoneOffset);
      case "XXXX":
      case "XX":
        return formatTimezone(timezoneOffset);
      case "XXXXX":
      case "XXX":
      default:
        return formatTimezone(timezoneOffset, ":");
    }
  },
  x: function x(date, token, _localize, options) {
    var originalDate = options._originalDate || date;
    var timezoneOffset = originalDate.getTimezoneOffset();
    switch (token) {
      case "x":
        return formatTimezoneWithOptionalMinutes(timezoneOffset);
      case "xxxx":
      case "xx":
        return formatTimezone(timezoneOffset);
      case "xxxxx":
      case "xxx":
      default:
        return formatTimezone(timezoneOffset, ":");
    }
  },
  O: function O(date, token, _localize, options) {
    var originalDate = options._originalDate || date;
    var timezoneOffset = originalDate.getTimezoneOffset();
    switch (token) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + formatTimezoneShort(timezoneOffset, ":");
      case "OOOO":
      default:
        return "GMT" + formatTimezone(timezoneOffset, ":");
    }
  },
  z: function z(date, token, _localize, options) {
    var originalDate = options._originalDate || date;
    var timezoneOffset = originalDate.getTimezoneOffset();
    switch (token) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + formatTimezoneShort(timezoneOffset, ":");
      case "zzzz":
      default:
        return "GMT" + formatTimezone(timezoneOffset, ":");
    }
  },
  t: function t(date, token, _localize, options) {
    var originalDate = options._originalDate || date;
    var timestamp = Math.floor(originalDate.getTime() / 1e3);
    return addLeadingZeros(timestamp, token.length);
  },
  T: function T(date, token, _localize, options) {
    var originalDate = options._originalDate || date;
    var timestamp = originalDate.getTime();
    return addLeadingZeros(timestamp, token.length);
  }
};
function formatTimezoneShort(offset, dirtyDelimiter) {
  var sign = offset > 0 ? "-" : "+";
  var absOffset = Math.abs(offset);
  var hours = Math.floor(absOffset / 60);
  var minutes = absOffset % 60;
  if (minutes === 0) {
    return sign + String(hours);
  }
  var delimiter = dirtyDelimiter || "";
  return sign + String(hours) + delimiter + addLeadingZeros(minutes, 2);
}
function formatTimezoneWithOptionalMinutes(offset, dirtyDelimiter) {
  if (offset % 60 === 0) {
    var sign = offset > 0 ? "-" : "+";
    return sign + addLeadingZeros(Math.abs(offset) / 60, 2);
  }
  return formatTimezone(offset, dirtyDelimiter);
}
function formatTimezone(offset, dirtyDelimiter) {
  var delimiter = dirtyDelimiter || "";
  var sign = offset > 0 ? "-" : "+";
  var absOffset = Math.abs(offset);
  var hours = addLeadingZeros(Math.floor(absOffset / 60), 2);
  var minutes = addLeadingZeros(absOffset % 60, 2);
  return sign + hours + delimiter + minutes;
}
var formatters_default = formatters2;

// ../../node_modules/date-fns/esm/_lib/format/longFormatters/index.js
var dateLongFormatter = function dateLongFormatter2(pattern, formatLong2) {
  switch (pattern) {
    case "P":
      return formatLong2.date({
        width: "short"
      });
    case "PP":
      return formatLong2.date({
        width: "medium"
      });
    case "PPP":
      return formatLong2.date({
        width: "long"
      });
    case "PPPP":
    default:
      return formatLong2.date({
        width: "full"
      });
  }
};
var timeLongFormatter = function timeLongFormatter2(pattern, formatLong2) {
  switch (pattern) {
    case "p":
      return formatLong2.time({
        width: "short"
      });
    case "pp":
      return formatLong2.time({
        width: "medium"
      });
    case "ppp":
      return formatLong2.time({
        width: "long"
      });
    case "pppp":
    default:
      return formatLong2.time({
        width: "full"
      });
  }
};
var dateTimeLongFormatter = function dateTimeLongFormatter2(pattern, formatLong2) {
  var matchResult = pattern.match(/(P+)(p+)?/) || [];
  var datePattern = matchResult[1];
  var timePattern = matchResult[2];
  if (!timePattern) {
    return dateLongFormatter(pattern, formatLong2);
  }
  var dateTimeFormat;
  switch (datePattern) {
    case "P":
      dateTimeFormat = formatLong2.dateTime({
        width: "short"
      });
      break;
    case "PP":
      dateTimeFormat = formatLong2.dateTime({
        width: "medium"
      });
      break;
    case "PPP":
      dateTimeFormat = formatLong2.dateTime({
        width: "long"
      });
      break;
    case "PPPP":
    default:
      dateTimeFormat = formatLong2.dateTime({
        width: "full"
      });
      break;
  }
  return dateTimeFormat.replace("{{date}}", dateLongFormatter(datePattern, formatLong2)).replace("{{time}}", timeLongFormatter(timePattern, formatLong2));
};
var longFormatters = {
  p: timeLongFormatter,
  P: dateTimeLongFormatter
};
var longFormatters_default = longFormatters;

// ../../node_modules/date-fns/esm/_lib/protectedTokens/index.js
var protectedDayOfYearTokens = ["D", "DD"];
var protectedWeekYearTokens = ["YY", "YYYY"];
function isProtectedDayOfYearToken(token) {
  return protectedDayOfYearTokens.indexOf(token) !== -1;
}
function isProtectedWeekYearToken(token) {
  return protectedWeekYearTokens.indexOf(token) !== -1;
}
function throwProtectedError(token, format2, input) {
  if (token === "YYYY") {
    throw new RangeError("Use `yyyy` instead of `YYYY` (in `".concat(format2, "`) for formatting years to the input `").concat(input, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  } else if (token === "YY") {
    throw new RangeError("Use `yy` instead of `YY` (in `".concat(format2, "`) for formatting years to the input `").concat(input, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  } else if (token === "D") {
    throw new RangeError("Use `d` instead of `D` (in `".concat(format2, "`) for formatting days of the month to the input `").concat(input, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  } else if (token === "DD") {
    throw new RangeError("Use `dd` instead of `DD` (in `".concat(format2, "`) for formatting days of the month to the input `").concat(input, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  }
}

// ../../node_modules/date-fns/esm/locale/en-US/_lib/formatDistance/index.js
var formatDistanceLocale = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
};
var formatDistance = function formatDistance2(token, count, options) {
  var result;
  var tokenValue = formatDistanceLocale[token];
  if (typeof tokenValue === "string") {
    result = tokenValue;
  } else if (count === 1) {
    result = tokenValue.one;
  } else {
    result = tokenValue.other.replace("{{count}}", count.toString());
  }
  if (options !== null && options !== void 0 && options.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return "in " + result;
    } else {
      return result + " ago";
    }
  }
  return result;
};
var formatDistance_default = formatDistance;

// ../../node_modules/date-fns/esm/locale/_lib/buildFormatLongFn/index.js
function buildFormatLongFn(args) {
  return function() {
    var options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    var width = options.width ? String(options.width) : args.defaultWidth;
    var format2 = args.formats[width] || args.formats[args.defaultWidth];
    return format2;
  };
}

// ../../node_modules/date-fns/esm/locale/en-US/_lib/formatLong/index.js
var dateFormats = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
};
var timeFormats = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
var formatLong = {
  date: buildFormatLongFn({
    formats: dateFormats,
    defaultWidth: "full"
  }),
  time: buildFormatLongFn({
    formats: timeFormats,
    defaultWidth: "full"
  }),
  dateTime: buildFormatLongFn({
    formats: dateTimeFormats,
    defaultWidth: "full"
  })
};
var formatLong_default = formatLong;

// ../../node_modules/date-fns/esm/locale/en-US/_lib/formatRelative/index.js
var formatRelativeLocale = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
};
var formatRelative = function formatRelative2(token, _date, _baseDate, _options) {
  return formatRelativeLocale[token];
};
var formatRelative_default = formatRelative;

// ../../node_modules/date-fns/esm/locale/_lib/buildLocalizeFn/index.js
function buildLocalizeFn(args) {
  return function(dirtyIndex, options) {
    var context = options !== null && options !== void 0 && options.context ? String(options.context) : "standalone";
    var valuesArray;
    if (context === "formatting" && args.formattingValues) {
      var defaultWidth = args.defaultFormattingWidth || args.defaultWidth;
      var width = options !== null && options !== void 0 && options.width ? String(options.width) : defaultWidth;
      valuesArray = args.formattingValues[width] || args.formattingValues[defaultWidth];
    } else {
      var _defaultWidth = args.defaultWidth;
      var _width = options !== null && options !== void 0 && options.width ? String(options.width) : args.defaultWidth;
      valuesArray = args.values[_width] || args.values[_defaultWidth];
    }
    var index = args.argumentCallback ? args.argumentCallback(dirtyIndex) : dirtyIndex;
    return valuesArray[index];
  };
}

// ../../node_modules/date-fns/esm/locale/en-US/_lib/localize/index.js
var eraValues = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
};
var quarterValues = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
};
var monthValues = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  wide: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
};
var dayValues = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
};
var dayPeriodValues = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
};
var formattingDayPeriodValues = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
};
var ordinalNumber = function ordinalNumber2(dirtyNumber, _options) {
  var number = Number(dirtyNumber);
  var rem100 = number % 100;
  if (rem100 > 20 || rem100 < 10) {
    switch (rem100 % 10) {
      case 1:
        return number + "st";
      case 2:
        return number + "nd";
      case 3:
        return number + "rd";
    }
  }
  return number + "th";
};
var localize = {
  ordinalNumber,
  era: buildLocalizeFn({
    values: eraValues,
    defaultWidth: "wide"
  }),
  quarter: buildLocalizeFn({
    values: quarterValues,
    defaultWidth: "wide",
    argumentCallback: function argumentCallback(quarter) {
      return quarter - 1;
    }
  }),
  month: buildLocalizeFn({
    values: monthValues,
    defaultWidth: "wide"
  }),
  day: buildLocalizeFn({
    values: dayValues,
    defaultWidth: "wide"
  }),
  dayPeriod: buildLocalizeFn({
    values: dayPeriodValues,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues,
    defaultFormattingWidth: "wide"
  })
};
var localize_default = localize;

// ../../node_modules/date-fns/esm/locale/_lib/buildMatchFn/index.js
function buildMatchFn(args) {
  return function(string) {
    var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var width = options.width;
    var matchPattern = width && args.matchPatterns[width] || args.matchPatterns[args.defaultMatchWidth];
    var matchResult = string.match(matchPattern);
    if (!matchResult) {
      return null;
    }
    var matchedString = matchResult[0];
    var parsePatterns = width && args.parsePatterns[width] || args.parsePatterns[args.defaultParseWidth];
    var key = Array.isArray(parsePatterns) ? findIndex(parsePatterns, function(pattern) {
      return pattern.test(matchedString);
    }) : findKey(parsePatterns, function(pattern) {
      return pattern.test(matchedString);
    });
    var value;
    value = args.valueCallback ? args.valueCallback(key) : key;
    value = options.valueCallback ? options.valueCallback(value) : value;
    var rest = string.slice(matchedString.length);
    return {
      value,
      rest
    };
  };
}
function findKey(object, predicate) {
  for (var key in object) {
    if (object.hasOwnProperty(key) && predicate(object[key])) {
      return key;
    }
  }
  return void 0;
}
function findIndex(array, predicate) {
  for (var key = 0; key < array.length; key++) {
    if (predicate(array[key])) {
      return key;
    }
  }
  return void 0;
}

// ../../node_modules/date-fns/esm/locale/_lib/buildMatchPatternFn/index.js
function buildMatchPatternFn(args) {
  return function(string) {
    var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var matchResult = string.match(args.matchPattern);
    if (!matchResult)
      return null;
    var matchedString = matchResult[0];
    var parseResult = string.match(args.parsePattern);
    if (!parseResult)
      return null;
    var value = args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0];
    value = options.valueCallback ? options.valueCallback(value) : value;
    var rest = string.slice(matchedString.length);
    return {
      value,
      rest
    };
  };
}

// ../../node_modules/date-fns/esm/locale/en-US/_lib/match/index.js
var matchOrdinalNumberPattern = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern = /\d+/i;
var matchEraPatterns = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
};
var parseEraPatterns = {
  any: [/^b/i, /^(a|c)/i]
};
var matchQuarterPatterns = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
};
var parseQuarterPatterns = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
};
var parseMonthPatterns = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^may/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
};
var parseDayPatterns = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
};
var matchDayPeriodPatterns = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
};
var parseDayPeriodPatterns = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
};
var match = {
  ordinalNumber: buildMatchPatternFn({
    matchPattern: matchOrdinalNumberPattern,
    parsePattern: parseOrdinalNumberPattern,
    valueCallback: function valueCallback(value) {
      return parseInt(value, 10);
    }
  }),
  era: buildMatchFn({
    matchPatterns: matchEraPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns,
    defaultParseWidth: "any"
  }),
  quarter: buildMatchFn({
    matchPatterns: matchQuarterPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns,
    defaultParseWidth: "any",
    valueCallback: function valueCallback2(index) {
      return index + 1;
    }
  }),
  month: buildMatchFn({
    matchPatterns: matchMonthPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns,
    defaultParseWidth: "any"
  }),
  day: buildMatchFn({
    matchPatterns: matchDayPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns,
    defaultParseWidth: "any"
  }),
  dayPeriod: buildMatchFn({
    matchPatterns: matchDayPeriodPatterns,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns,
    defaultParseWidth: "any"
  })
};
var match_default = match;

// ../../node_modules/date-fns/esm/locale/en-US/index.js
var locale = {
  code: "en-US",
  formatDistance: formatDistance_default,
  formatLong: formatLong_default,
  formatRelative: formatRelative_default,
  localize: localize_default,
  match: match_default,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var en_US_default = locale;

// ../../node_modules/date-fns/esm/_lib/defaultLocale/index.js
var defaultLocale_default = en_US_default;

// ../../node_modules/date-fns/esm/format/index.js
var formattingTokensRegExp = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g;
var longFormattingTokensRegExp = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;
var escapedStringRegExp = /^'([^]*?)'?$/;
var doubleQuoteRegExp = /''/g;
var unescapedLatinCharacterRegExp = /[a-zA-Z]/;
function format(dirtyDate, dirtyFormatStr, options) {
  var _ref, _options$locale, _ref2, _ref3, _ref4, _options$firstWeekCon, _options$locale2, _options$locale2$opti, _defaultOptions$local, _defaultOptions$local2, _ref5, _ref6, _ref7, _options$weekStartsOn, _options$locale3, _options$locale3$opti, _defaultOptions$local3, _defaultOptions$local4;
  requiredArgs(2, arguments);
  var formatStr = String(dirtyFormatStr);
  var defaultOptions2 = getDefaultOptions();
  var locale2 = (_ref = (_options$locale = options === null || options === void 0 ? void 0 : options.locale) !== null && _options$locale !== void 0 ? _options$locale : defaultOptions2.locale) !== null && _ref !== void 0 ? _ref : defaultLocale_default;
  var firstWeekContainsDate = toInteger((_ref2 = (_ref3 = (_ref4 = (_options$firstWeekCon = options === null || options === void 0 ? void 0 : options.firstWeekContainsDate) !== null && _options$firstWeekCon !== void 0 ? _options$firstWeekCon : options === null || options === void 0 ? void 0 : (_options$locale2 = options.locale) === null || _options$locale2 === void 0 ? void 0 : (_options$locale2$opti = _options$locale2.options) === null || _options$locale2$opti === void 0 ? void 0 : _options$locale2$opti.firstWeekContainsDate) !== null && _ref4 !== void 0 ? _ref4 : defaultOptions2.firstWeekContainsDate) !== null && _ref3 !== void 0 ? _ref3 : (_defaultOptions$local = defaultOptions2.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.firstWeekContainsDate) !== null && _ref2 !== void 0 ? _ref2 : 1);
  if (!(firstWeekContainsDate >= 1 && firstWeekContainsDate <= 7)) {
    throw new RangeError("firstWeekContainsDate must be between 1 and 7 inclusively");
  }
  var weekStartsOn = toInteger((_ref5 = (_ref6 = (_ref7 = (_options$weekStartsOn = options === null || options === void 0 ? void 0 : options.weekStartsOn) !== null && _options$weekStartsOn !== void 0 ? _options$weekStartsOn : options === null || options === void 0 ? void 0 : (_options$locale3 = options.locale) === null || _options$locale3 === void 0 ? void 0 : (_options$locale3$opti = _options$locale3.options) === null || _options$locale3$opti === void 0 ? void 0 : _options$locale3$opti.weekStartsOn) !== null && _ref7 !== void 0 ? _ref7 : defaultOptions2.weekStartsOn) !== null && _ref6 !== void 0 ? _ref6 : (_defaultOptions$local3 = defaultOptions2.locale) === null || _defaultOptions$local3 === void 0 ? void 0 : (_defaultOptions$local4 = _defaultOptions$local3.options) === null || _defaultOptions$local4 === void 0 ? void 0 : _defaultOptions$local4.weekStartsOn) !== null && _ref5 !== void 0 ? _ref5 : 0);
  if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  }
  if (!locale2.localize) {
    throw new RangeError("locale must contain localize property");
  }
  if (!locale2.formatLong) {
    throw new RangeError("locale must contain formatLong property");
  }
  var originalDate = toDate(dirtyDate);
  if (!isValid(originalDate)) {
    throw new RangeError("Invalid time value");
  }
  var timezoneOffset = getTimezoneOffsetInMilliseconds(originalDate);
  var utcDate = subMilliseconds(originalDate, timezoneOffset);
  var formatterOptions = {
    firstWeekContainsDate,
    weekStartsOn,
    locale: locale2,
    _originalDate: originalDate
  };
  var result = formatStr.match(longFormattingTokensRegExp).map(function(substring) {
    var firstCharacter = substring[0];
    if (firstCharacter === "p" || firstCharacter === "P") {
      var longFormatter = longFormatters_default[firstCharacter];
      return longFormatter(substring, locale2.formatLong);
    }
    return substring;
  }).join("").match(formattingTokensRegExp).map(function(substring) {
    if (substring === "''") {
      return "'";
    }
    var firstCharacter = substring[0];
    if (firstCharacter === "'") {
      return cleanEscapedString(substring);
    }
    var formatter = formatters_default[firstCharacter];
    if (formatter) {
      if (!(options !== null && options !== void 0 && options.useAdditionalWeekYearTokens) && isProtectedWeekYearToken(substring)) {
        throwProtectedError(substring, dirtyFormatStr, String(dirtyDate));
      }
      if (!(options !== null && options !== void 0 && options.useAdditionalDayOfYearTokens) && isProtectedDayOfYearToken(substring)) {
        throwProtectedError(substring, dirtyFormatStr, String(dirtyDate));
      }
      return formatter(utcDate, substring, locale2.localize, formatterOptions);
    }
    if (firstCharacter.match(unescapedLatinCharacterRegExp)) {
      throw new RangeError("Format string contains an unescaped latin alphabet character `" + firstCharacter + "`");
    }
    return substring;
  }).join("");
  return result;
}
function cleanEscapedString(input) {
  var matched = input.match(escapedStringRegExp);
  if (!matched) {
    return input;
  }
  return matched[1].replace(doubleQuoteRegExp, "'");
}

// src/UtilsStringFormat.ts
var handleFormatGuestsAndTimeSelections = (guestsNumber, selectedTime) => {
  const formatedSelectedTime = format(isValid(selectedTime) ? selectedTime : new Date(), "h:mm aaa");
  return `${guestsNumber}|${formatedSelectedTime}`;
};
var handleFormatCarouselView = (page, carouselId) => {
  const pageUrl = page === "Home" ? "/" : `/${page.toLocaleLowerCase()}`;
  return `${pageUrl}|${page}/${carouselId}`;
};
var capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
var handleFormatSpecialEventAction = (eventName, partySize, date, time) => `${eventName}|${partySize}|${date}|${time}`;

// src/UtilsDineRewardsFormsLocation.ts
var formsLocation = /* @__PURE__ */ ((formsLocation2) => {
  formsLocation2["header"] = "header";
  formsLocation2["footer"] = "footer";
  formsLocation2["modal"] = "modal";
  return formsLocation2;
})(formsLocation || {});
var ToFormLocation = (variant) => {
  if (variant === "primary")
    return "footer" /* footer */;
  if (variant === "secondary")
    return "header" /* header */;
  return "modal" /* modal */;
};

// src/TabTrap.ts
var TabTrap = (id, focusOnFirstElement) => {
  var modal = document.getElementById(id);
  if (modal === null)
    return;
  modal?.addEventListener("keydown", trapTabKey);
  var focusableElementsString = 'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, [tabindex="0"]';
  var focusableElements = modal?.querySelectorAll(focusableElementsString);
  var focusableElementsArray = Array.prototype.slice.call(focusableElements);
  var firstTabStop = focusableElementsArray[0];
  var lastTabStop = focusableElementsArray[focusableElementsArray.length - 1];
  if (focusOnFirstElement)
    firstTabStop.focus();
  function trapTabKey(e2) {
    if (e2.key === "Tab") {
      if (e2.shiftKey) {
        if (document.activeElement === firstTabStop) {
          e2.preventDefault();
          lastTabStop.focus();
        }
      } else {
        if (document.activeElement === lastTabStop) {
          e2.preventDefault();
          firstTabStop.focus();
        }
      }
    }
  }
};

// src/GetQueryStringParam.ts
var GetQueryStringParam = (key) => {
  if (!("location" in window)) {
    console.error(`window object not available`);
    return null;
  }
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

// src/RandomNumber.ts
var RandomNumber = () => {
  try {
    return crypto.getRandomValues(new Uint32Array(1))[0] / (4294967295 + 1);
  } catch {
    return Math.random();
  }
};
var RandomInt = (min, max) => {
  const _min = Math.ceil(min);
  const _max = Math.floor(max);
  return Math.floor(RandomNumber() * (_max - _min + 1) + _min);
};

// src/UtilsIsObjectEmpty.ts
var UtilsIsObjectEmpty = (obj) => {
  const type = typeof obj;
  const isObject = obj !== null && type === "object";
  return isObject && Object.keys(obj).length === 0;
};

// src/UtilsCalculateDisplayTime.ts
var UtilsCalculateDisplayTime = (text) => {
  const readingWordsPerSecond = 3.33;
  const extraTime = 2e3;
  const maximumToastTime = 1e4;
  const textTimeLength = (text.split(" ").length - 1) / readingWordsPerSecond * 1e3 + extraTime;
  return Math.min(textTimeLength, maximumToastTime);
};

// src/UtilsIsAppOnly.ts
function isAppOnly(metadataHandler, isCategory) {
  const APP_ONLY = isCategory ? { key: "category-highlight-display", value: "app-only-category" } : { key: "product-highlight-display", value: "app-only" };
  return metadataHandler.metadata?.find((metadata) => metadata?.key === APP_ONLY.key)?.value === APP_ONLY.value;
}
var UtilsIsAppOnly = (menu, productId) => {
  const productCategory = menu?.categories?.find(
    (category) => category?.products?.find((product2) => product2.id === productId)
  );
  if (!productCategory)
    return false;
  const product = productCategory?.products?.find((product2) => product2.id === productId);
  if (!product)
    return false;
  return isAppOnly(product, false) || isAppOnly(productCategory, true);
};
var UtilsIsAppOnlyCategory = (category) => {
  return isAppOnly(category, true);
};

// src/UtilsDate.ts
var convertStringWithoutTimeZoneToDate = (date) => {
  const year = Number.parseInt(date.split("-")[0]);
  const monthIndex = Number.parseInt(date.split("-")[1]) - 1;
  const day = Number.parseInt(date.split("-")[2]);
  return new Date(year, monthIndex, day);
};
var getDateWithOffset = (offset) => {
  const now = new Date();
  const utcTime = now.getTime() + now.getTimezoneOffset() * 6e4;
  const offsetTime = utcTime + offset * 60 * 6e4;
  return new Date(offsetTime);
};
var formatTime = (seatingData) => {
  const [hours, minutes] = seatingData?.TimeSlot.split(":") || "";
  const dateTime = new Date();
  dateTime.setHours(Number(hours));
  dateTime.setMinutes(Number(minutes));
  return format(dateTime, "h:mm a");
};

// src/CalendarUtils.ts
import { CalendarTypes, ConvertToDate, HandoffMethods } from "@maverick/entity";
var retireveCalendarType = (handoffMethod) => {
  let calendarType = "";
  switch (handoffMethod) {
    case HandoffMethods.Curbside:
      calendarType = CalendarTypes.Curbside;
      break;
    case HandoffMethods.Delivery:
      calendarType = CalendarTypes.Delivery;
      break;
    case HandoffMethods.Pickup:
      calendarType = CalendarTypes.Pickup;
      break;
  }
  return calendarType;
};
var retrieveHandoffCalendar = (handoffMethod, restaurant) => {
  const calendarType = retireveCalendarType(handoffMethod);
  let handoffCalendar = restaurant.calendar?.find((calendar) => calendar.type === calendarType)?.ranges;
  if (!handoffCalendar && isOnlyPickup(restaurant, calendarType)) {
    handoffCalendar = restaurant.calendar?.find((calendar) => calendar.type === CalendarTypes.Business)?.ranges;
  }
  return handoffCalendar;
};
var isOnlyPickup = (restaurant, calendarType) => {
  return calendarType === CalendarTypes.Pickup && !restaurant.supportscurbside && restaurant.canpickup;
};
var retrieveBusinessCalendar = (restaurant) => {
  const businessCalendar = restaurant.calendar?.find((calendar) => calendar.type === CalendarTypes.Business)?.ranges;
  return businessCalendar;
};
var onlyPickUpCalendar = (restaurant) => {
  const noCurbsideCalendar = !restaurant.calendar?.some((calendar) => calendar.type === CalendarTypes.Curbside);
  const noDeliveryCalendar = !restaurant.calendar?.some((calendar) => calendar.type === CalendarTypes.Delivery);
  const pickUpCalendar = restaurant.calendar?.some((calendar) => calendar.type === CalendarTypes.Pickup);
  return noCurbsideCalendar && noDeliveryCalendar && pickUpCalendar;
};
var retrieveRestaurantHours = (restaurant, date, handoffMethod) => {
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let basketHandoffCalendar;
  if (handoffMethod)
    basketHandoffCalendar = retrieveHandoffCalendar(handoffMethod, restaurant);
  else
    basketHandoffCalendar = retrieveBusinessCalendar(restaurant);
  const weekCalendar = basketHandoffCalendar ? basketHandoffCalendar : [];
  if (weekCalendar) {
    const open = weekCalendar.find((day) => day.weekday === weekDays[date.getDay()])?.start;
    const close = weekCalendar.find((day) => day.weekday === weekDays[date.getDay()])?.end;
    return { open, close };
  }
  return { open: void 0, close: void 0 };
};
var formatDate = (timePlaced, formatType) => {
  const convertedDate = ConvertToDate(timePlaced);
  return format(convertedDate, formatType);
};
var formatHour = (date) => {
  const formattedDate = date.getMinutes() == 0 ? format(date, "haaaaa'm'") : format(date, "h:mmaaaaa'm'");
  return formattedDate;
};
var CreateIsoDate = (dateString) => {
  const date = new Date(dateString);
  const utcTime = date.getTime() + date.getTimezoneOffset() * 6e4;
  return new Date(utcTime);
};

// src/StringUtils.ts
var capitalizeOnlyFirstLetter = (text) => {
  const lowerCaseText = text.toLowerCase();
  const parameterizedText = lowerCaseText.replace(new RegExp("(?:\\b|_)([a-z])", "g"), function(char) {
    return char.toUpperCase();
  });
  return parameterizedText;
};

// src/RestaurantUtils.ts
var isPickUpOnly = (restaurant) => {
  if (restaurant.canpickup && !restaurant.supportscurbside) {
    return true;
  }
  return false;
};
var checkRestaurantAvailability = (restaurant, handoffMethod) => {
  const currentTime = new Date();
  const acceptsOrderBeforeOpening = restaurant.supportedtimemodes?.includes?.("advance");
  let restaurantHours;
  if (handoffMethod)
    restaurantHours = retrieveRestaurantHours(restaurant, currentTime, handoffMethod);
  else
    restaurantHours = retrieveRestaurantHours(restaurant, currentTime);
  if (!restaurant.isavailable)
    return false;
  if (restaurantHours.open === void 0 || restaurantHours.close === void 0)
    return false;
  if (currentTime.getDay() !== restaurantHours.open.getDay())
    return false;
  if (currentTime < restaurantHours.open && !acceptsOrderBeforeOpening)
    return false;
  if (currentTime > restaurantHours.close)
    return false;
  return true;
};
var handleRestaurantInfo = (restaurant) => {
  const { name, streetaddress, city, state, zip } = restaurant;
  const restaurantName = capitalizeOnlyFirstLetter(name);
  const restaurantAddress = `${capitalizeOnlyFirstLetter(streetaddress)} - ${capitalizeOnlyFirstLetter(
    city
  )}, ${state.toUpperCase()} ${zip}`;
  return { restaurantName, restaurantAddress };
};
var filterRestaurantHours = (calendars, type = "business") => {
  const businessCalendar = calendars.find((calendar) => calendar.type === type)?.ranges;
  if (!businessCalendar)
    return;
  const currentDay = format(new Date(), "EEE");
  const currentDayCalendar = businessCalendar.find((date) => date.weekday === currentDay);
  if (!currentDayCalendar || !currentDayCalendar.start || !currentDayCalendar.end)
    return;
  if (!(currentDayCalendar.start instanceof Date) || !(currentDayCalendar.end instanceof Date))
    return;
  const formattedStartHour = formatHour(currentDayCalendar?.start);
  const formattedEndHour = formatHour(currentDayCalendar?.end);
  const restaurantHours = `${formattedStartHour} - ${formattedEndHour}`;
  return restaurantHours;
};
var isProductDineInOnly = (product) => {
  if (product.unavailablehandoffmodes.includes("pickup") && product.unavailablehandoffmodes.includes("curbside") && product.unavailablehandoffmodes.includes("delivery") && product.unavailablehandoffmodes.includes("drivethru") && product.unavailablehandoffmodes.includes("dispatch") && !product.unavailablehandoffmodes.includes("dinein")) {
    return true;
  }
  return false;
};
var isProductOnlineOnly = (product) => {
  if (product.unavailablehandoffmodes.includes("dinein")) {
    return true;
  }
  return false;
};

// src/consts/GeolocationPermission.ts
var BrowserPermissionLinks = {
  Chrome: "https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop",
  MobileSafari: "https://support.apple.com/en-us/HT207092",
  DesktopSafari: "https://support.apple.com/guide/mac-help/allow-apps-to-detect-the-location-of-your-mac-mh35873/mac",
  Firefox: "https://mzl.la/3QmMN2s",
  Edg: "https://support.microsoft.com/en-us/microsoft-edge/location-and-privacy-in-microsoft-edge-31b5d154-0b1b-90ef-e389-7c7d4ffe7b04",
  OPR: "https://help.opera.com/en/geolocation/"
};

// src/UserCoordinates.ts
var getUserCoordinates = async (options = {}) => {
  const getCurrentPosition = async () => {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (success) => {
          resolve(success.coords);
        },
        () => {
          resolve(null);
        }
      );
    });
  };
  if (!options.pageLoad) {
    return getCurrentPosition();
  }
  const { state } = await navigator.permissions.query({
    name: "geolocation"
  });
  if (state === "granted") {
    return getCurrentPosition();
  }
  return null;
};

// src/ModifiersUtils.ts
import { OptionGroup } from "@maverick/entity";
var deepClone = (obj) => {
  return structuredClone(obj);
};
var normalizeOptionGroups = (original) => {
  const originalClone = deepClone(original);
  const normalized = new Array();
  const normalizeMasterOptionGroup = (optionGroup, isRoot = false) => {
    const optionGroupClone = deepClone(optionGroup);
    optionGroup.options.forEach((o) => o.modifiers = null);
    if (isRoot)
      normalized.push(optionGroup);
    for (const option of optionGroupClone.options) {
      const optionGroupAlreadyAdded = normalized.find((og) => og.id === optionGroup.id);
      if (!optionGroupAlreadyAdded)
        normalized.push(optionGroup);
      option.modifiers?.forEach((modifier) => {
        if (!OptionGroup.IsMasterOptionGroup(optionGroup)) {
          normalized.push(modifier);
        } else {
          normalizeMasterOptionGroup(modifier);
        }
      });
    }
  };
  for (const optionGroup of originalClone) {
    if (!OptionGroup.IsMasterOptionGroup(optionGroup)) {
      normalized.push(optionGroup);
      continue;
    }
    normalizeMasterOptionGroup(optionGroup, true);
  }
  return normalized;
};
var findFirstSideOptions = (normalized) => {
  const firstSideOptions = [];
  const seenOptionIds = /* @__PURE__ */ new Set();
  normalized.forEach((optionGroup) => {
    const description = optionGroup.description.toLowerCase();
    if (description === "first side option" || description === "side option") {
      const uniqueOptions = optionGroup.options.filter((option) => {
        if (!seenOptionIds.has(option.chainoptionid)) {
          seenOptionIds.add(option.chainoptionid);
          return true;
        }
        return false;
      });
      firstSideOptions.push(...uniqueOptions);
    }
  });
  return firstSideOptions;
};
var optionsBySideCategory = (options) => {
  const separatedOptions = [];
  options.forEach((option) => {
    const sideCategoryMetadata = option?.metadata?.find((meta) => meta.key === "sideCategory");
    if (sideCategoryMetadata) {
      let categoryOptions = separatedOptions.find((category) => category.category === sideCategoryMetadata.value);
      if (!categoryOptions) {
        categoryOptions = {
          category: sideCategoryMetadata.value,
          options: []
        };
        separatedOptions.push(categoryOptions);
      }
      categoryOptions.options.push(option);
    }
  });
  return separatedOptions;
};
export {
  BrowserPermissionLinks,
  CreateIsoDate,
  DESKTOP_BREAKPOINT,
  DESKTOP_LARGE_BREAKPOINT,
  DESKTOP_SMALL_BREAKPOINT,
  DateWithoutUTCFormat,
  instance as GaEvent,
  GetCookie,
  GetQueryStringParam,
  InputModes,
  IsEmailValid,
  PaymentType,
  RandomInt,
  RandomNumber,
  Sleep,
  Slugify,
  Storage,
  TABLET_BREAKPOINT,
  TabTrap,
  ToFormLocation,
  ULTRAWIDE_BREAKPOINT,
  UtilsCalculateDisplayTime,
  UtilsElementPosition,
  UtilsGetDateDiffInDays,
  UtilsGetProductTag,
  UtilsIsAppOnly,
  UtilsIsAppOnlyCategory,
  UtilsIsObjectEmpty,
  UtilsMask,
  UtilsMoneyFormatter,
  UtilsUuid,
  capitalizeFirstLetter,
  capitalizeOnlyFirstLetter,
  checkRestaurantAvailability,
  convertStringWithoutTimeZoneToDate,
  filterRestaurantHours,
  findFirstSideOptions,
  formatDate,
  formatHour,
  formatTime,
  formsLocation,
  getDateWithOffset,
  getUserCoordinates,
  handleFormatCarouselView,
  handleFormatGuestsAndTimeSelections,
  handleFormatSpecialEventAction,
  handleRestaurantInfo,
  handleWithPopUp,
  isPickUpOnly,
  isProductDineInOnly,
  isProductOnlineOnly,
  normalizeOptionGroups,
  onlyPickUpCalendar,
  optionsBySideCategory,
  retireveCalendarType,
  retrieveBusinessCalendar,
  retrieveHandoffCalendar,
  retrieveRestaurantHours
};
